define("discourse/plugins/discourse-teambuild/initializers/setup-teambuilding", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "setup-teambuilding",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", api => {
        const currentUser = api.getCurrentUser();
        if (currentUser?.can_access_teambuild) {
          api.addCommunitySectionLink(baseSectionLink => {
            return class TeambuildSectionLink extends baseSectionLink {
              name = "team-building";
              route = "teamBuild.progress";
              text = (() => this.siteSettings.teambuild_name)();
              title = (() => this.siteSettings.teambuild_name)();
            };
          });
        }
      });
    }
  };
});