define("discourse/plugins/discourse-teambuild/discourse/models/teambuild-target", ["exports", "discourse/lib/ajax", "discourse/models/rest"], function (_exports, _ajax, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Types = void 0;
  const Types = _exports.Types = {
    REGULAR: 1,
    USER_GROUP: 2
  };
  var _default = _exports.default = _rest.default.extend({
    swapPosition(other) {
      let tmp = this.position;
      this.set("position", other.position);
      other.set("position", tmp);
      return (0, _ajax.ajax)(`/team-build/targets/${this.id}/swap-position`, {
        method: "PUT",
        data: {
          other_id: other.id
        }
      });
    },
    complete(userId) {
      return (0, _ajax.ajax)(`/team-build/complete/${this.id}/${userId}`, {
        method: "PUT"
      });
    },
    undo(userId) {
      return (0, _ajax.ajax)(`/team-build/undo/${this.id}/${userId}`, {
        method: "DELETE"
      });
    }
  });
});