define("discourse/plugins/discourse-teambuild/components/teambuild-choice", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "",
    completed: (0, _object.computed)("progress.completed.[]", function () {
      return this.progress.isComplete(this.target, this.userId);
    }),
    actions: {
      complete() {
        this.progress.complete(this.target, this.userId);
      },
      undo() {
        this.progress.undo(this.target, this.userId);
      }
    }
  });
});