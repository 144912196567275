define("discourse/plugins/discourse-teambuild/discourse/routes/team-build-progress", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model() {
      return this.store.find("teambuild-progress", this.currentUser.username);
    },
    setupController(controller, progress) {
      controller.setProperties({
        progress
      });
    }
  });
});