define("discourse/plugins/discourse-teambuild/discourse/controllers/team-build-manage", ["exports", "@ember/controller", "@ember/object/computed", "discourse/plugins/discourse-teambuild/discourse/models/teambuild-target"], function (_exports, _controller, _computed, _teambuildTarget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    targets: null,
    targetSort: ["position"],
    sortedTargets: (0, _computed.sort)("targets", "targetSort"),
    actions: {
      move(idx, direction) {
        let item = this.sortedTargets[idx];
        let other = this.sortedTargets[idx + direction];
        if (item && other) {
          item.swapPosition(other);
        }
      },
      newTarget() {
        let maxPosition = 0;
        if (this.targets.length > 0) {
          maxPosition = Math.max(...this.targets.map(t => t.position));
        }
        this.targets.pushObject(this.store.createRecord("teambuild-target", {
          target_type_id: _teambuildTarget.Types.REGULAR,
          position: maxPosition + 1
        }));
      },
      removeTarget(t) {
        this.targets.removeObject(t);
      }
    }
  });
});