define("discourse/plugins/discourse-teambuild/discourse/templates/team-build", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="team-build">
    <h1>{{siteSettings.teambuild_name}}</h1>
  
    <ul class="nav nav-pills">
      <NavItem
        @route="teamBuild.index"
        @label="discourse_teambuild.scores.title"
      />
      <NavItem
        @route="teamBuild.progress"
        @label="discourse_teambuild.progress.title"
      />
      {{#if currentUser.staff}}
        <NavItem
          @route="teamBuild.manage"
          @label="discourse_teambuild.manage.title"
          @icon="wrench"
        />
      {{/if}}
    </ul>
  
    {{outlet}}
  </div>
  */
  {
    "id": "o72yxOl9",
    "block": "[[[10,0],[14,0,\"team-build\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[30,0,[\"siteSettings\",\"teambuild_name\"]]],[13],[1,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\",\"@label\"],[\"teamBuild.index\",\"discourse_teambuild.scores.title\"]],null],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\",\"@label\"],[\"teamBuild.progress\",\"discourse_teambuild.progress.title\"]],null],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"staff\"]],[[[1,\"      \"],[8,[39,0],null,[[\"@route\",\"@label\",\"@icon\"],[\"teamBuild.manage\",\"discourse_teambuild.manage.title\",\"wrench\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-teambuild/discourse/templates/team-build.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `currentUser` property path was used in the `discourse/plugins/discourse-teambuild/discourse/templates/team-build.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentUser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"nav-item\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-teambuild/discourse/templates/team-build.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});