define("discourse/plugins/discourse-teambuild/discourse/routes/team-build-manage", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model() {
      return this.store.findAll("teambuild-target");
    },
    setupController(controller, targets) {
      controller.setProperties({
        targets,
        groups: targets.extras.groups
      });
    }
  });
});